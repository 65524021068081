<template>
   <div  class="inside-bg" id="landing-page">
    <div class="container-fluid header">
      <div class="container">
        <div class="row">
          <div class="logo col-6">
            <div><img :src="$S3_url+'/skillhire/assets/skill-hire-logo.svg'" width="160"></div>
            <div>by <a href="/">Hireclap</a></div>
          </div>
          <div class="logo col-6 text-right">
            <a class="btn btn-primary text-light mt-1" target="_blank" href="/recruit/post-job?src=skillhire">Start hiring</a>
          </div>
        </div>
    </div>
    </div>
    <div class="container skillhire">

      <div class="content-body">

        <div class="banner-slide">
            <div class="banner2-container banner-row">
              <div class="banner-image">
                <picture>
                  <img loading="lazy" width="290" height="295"  :src="$S3_url+'/skillhire/assets/banner-img2.svg'" alt="Post your video resume and search jobs for freshers and entry-level hiring" class="banner_img">
                </picture>
              </div>

              <div class="banner">
                <div class="mt-5">
                  <div class="banner-text">
                  
                  <h4>Skilled Talent @Zero Cost </h4>
                  <ul class="banner-li">
                    <li>Hire from the largest pool of trained & certified candidates
by leading training/upskilling companies across the country</li>
<li>SkillHire is the one-stop platform to hire skilled and job-ready candidates for your organisation.</li>
<li>Find your ideal match, faster at zero cost.</li>
                  </ul>
                  <a class="btn btn-primary text-light" target="_blank" href="/recruit/post-job?src=skillhire">Start hiring</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="white-wrapper">
                   
                   <div class="d-flex mt-5 text-center feature">
                     <div class="col-4"><img  :src="$S3_url+'/skillhire/assets/hotel-line.svg'" alt=""><br><span>5000+ </span>Registered companies</div>
                     <div class="vertical-div"></div>
                     <div class="col-4"><img  :src="$S3_url+'/skillhire/assets/candidates.svg'" alt=""><br><span>3 million+ </span>Candidates registered</div>
                     <div class="vertical-div"></div>
                     <div class="col-4"><img  :src="$S3_url+'/skillhire/assets/collage.svg'" alt=""><br><span>1000+</span> Associated colleges</div>
                   </div>
                 </div>

                 <div class="white-wrapper mb-5 mt-3">
          <h4>Why hire from SkillHire ?</h4>
          <ul class="tick-bullet row">
            <li class="col-sm-12 col-md-12"><b>Trained candidates pool</b><p>SkillHire showcases skilled certified candidates pool by leading training/upskilling companies in India. Pick the right talent based on skills aligned with the positions that you are hiring for from a large pool across the country.</p></li>
            <li class="col-sm-12 col-md-12"><b>Hire from skill pool</b><p>Hire from the largest pool of trained &amp; certified candidates by leading training &amp; upskilling companies across the country. You can hire for any skill, any domain &amp; vertical from this diverse pool.</p></li>
            <li class="col-sm-12 col-md-12"><b>Dedicated hiring support</b><p>SkillHire's team, with decades of experience in entry-level hiring, will support your hiring end-to-end. Our hiring platform, with tools like assessments and video interviews, makes your hiring process systematic, transparent, and easy to manage.</p></li>
            <li class="col-sm-12 col-md-12"><b>Zero hiring cost</b><p>Hire the best job-ready talent directly from us at no cost to your organisation.</p></li>
          
          </ul>
          
        </div>

        <div class="white-wrapper mb-5 mt-3">
          <h4>How it works</h4>
          <ul class="tick-bullet row">
            <li class="col-sm-12 col-md-6">Post your job</li>
            <li class="col-sm-12 col-md-6">Start receiving applications from our candidate pool</li>
            <li class="col-sm-12 col-md-6">Recruit using our hiring platform</li>
            <li class="col-sm-12 col-md-6">Hire the best from the application pool</li>
          
          </ul>
          
        </div>


       
        <div class="mb-5">
          <h4>Testimonials</h4>
          <div class="testimonial-section">
            <div class="testimonial">
              "Hireclap efficiently sourced qualified candidates for our JSW fresher hiring
              process, delivering them within the specified timeframe."
              <div class="testimonial-name">Amit</div>
              <div class="testimonial-company">JSW</div>
            </div>
            <div class="dotted-line"></div>
            <div class="testimonial">
              "Thanks to Hireclap, our candidate sourcing programs have seen a substantial improvement
              in lead generation. Their successful digital outreach campaigns have played a crucial
              role in connecting us with a wide pool of talented candidates."
              <div class="testimonial-name">Shikhar</div>
              <div class="testimonial-company">Masai School</div>
            </div>
            <div class="dotted-line"></div>
            <div class="testimonial">
              "Hireclap's new and relevant database of candidates, comprising recent college graduates
              actively seeking upskilling or better job prospects, yielding better outcomes. The option
              to filter candidates based on programs significantly improved results. Keep up the good work!"
              <div class="testimonial-name">Anandita</div>
              <div class="testimonial-company">upGrad</div>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <h5>FAQ</h5>
          <v-expansion-panels class="mt-5">
          <v-expansion-panel v-for="(item, index) in faqItems" :key="index">
            <v-expansion-panel-header>{{ item.question }}</v-expansion-panel-header>
            <v-expansion-panel-content>{{ item.answer }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </div>
      </div>
    </div>
    <div class="footer"><div class="container p-3"><div class="row"><div class="col-lg-5 col-md-12 alignmob"><p class="content-center mb-0">© 2023 <a href="" style="color: rgb(255, 255, 255);">Hireclap</a> | Hire best matching candidates</p></div><div class="col-lg-4 col-md-6 alignmob"><p class="content-center mb-0"><small class="mb-0"><a href="/privacy" target="noopener" class="flink text-decoration-none">Privacy Policy </a>|<a href="/terms" target="noopener" class="flink text-decoration-none"> Terms &amp; Conditions</a></small></p></div><div class="col-lg-3 col-md-6 alignmob"><a href="https://www.facebook.com/Hireclap" aria-label="facebook" target="noopener"><i class="mdi mdi-facebook mdi-footer"></i></a><a href="https://twitter.com/hireclap" aria-label="twitter" target="noopener"><i class="mdi mdi-twitter mdi-footer"></i></a><a href="https://www.linkedin.com/company/hireclap" aria-label="linkedin" target="noopener"><i class="mdi mdi-linkedin mdi-footer"></i></a><a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q" aria-label="youtube" target="noopener"><i class="mdi mdi-youtube mdi-footer"></i></a><a href="https://www.instagram.com/hireclap" aria-label="instagram" target="noopener"><i class="mdi mdi-instagram mdi-footer"></i></a></div></div></div></div>
   </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
var jsonld = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "Hireclap",
  "url": process.env.VUE_APP_URL,
  "logo": "https://d22r5huxahsjvt.cloudfront.net/Assets/images/hireclap-logo-HD.png",
  "contactPoint": [
    {
      "@type": "ContactPoint",
      "telephone": "+919037156256",
      "contactType": "Customer Service",
      "areaServed": "India"
    }
  ]
};

var faqJson = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How much is the hiring cost associated with recruiting a candidate?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Zero. You can recruit exceptional talent without any cost to your company."
      }
    },
    {
      "@type": "Question",
      "name": "How can I find the right candidate to hire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can post your job opening & receive relevant applications from a trained candidate pool with hands-on experience. Employers can then use our platform to review, shortlist, assess, and interview the candidates, ultimately selecting the best fits for their roles."
      }
    },
    {
      "@type": "Question",
      "name": "How can I post job vacancies for free on SkillHire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Employers just need to use our 'Post a Job' page. Your job openings will be showcased to our registered institutes and shared with a pool of trained candidates, inviting applications."
      }
    },
    {
      "@type": "Question",
      "name": "What are the advantages of hiring employees from SkillHire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SkillHire provides organizations with trained, certified candidates who have hands-on experience, all at no cost. We also provide hiring tools & dedicated hiring support to simplify the hiring process."
      }
    },
    {
      "@type": "Question",
      "name": "Is there any hiring limit on SkillHire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You're free to hire as many candidates as you need from our pool."
      }
    },
    {
      "@type": "Question",
      "name": "From which all locations can I recruit candidates using SkillHire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Candidates from all over India are available."
      }
    },
    {
      "@type": "Question",
      "name": "Which all job roles can I hire using SkillHire?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can hire for a wide range of skills and sectors. We have registered training and upskilling organizations from all major segments."
      }
    }
  ]
};


var breadcrumbJson = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": window.location.href
    }
  ]
};
export default {
    name:'InstituteCorporate',
    data: function(){
      return{
        institutes: [],
        action: new Actions(),
        filter: {
          course_id: null,
          city_id:null
        },
        courses:[],
        locations: [],
         faqItems: [
        {
          question: "How much is the hiring cost associated to recruit a candidate?",
          answer: "Zero. You can recruit exceptional talent without any cost to your company."
        },
        {
          question: "How can I find the right candidate to hire?",
          answer: "You can post your job opening & receive relevant applications from trained candidates pool with hands-on experience. Employers can then use our platform to review, shortlist, assess and interview the candidates, ultimately selecting the best fits for their roles."
        },
        {
          question: "How can I post job vacancies for free on SkillHire?",
          answer: "Employers just need to use our 'Post a Job' page. Your job openings will be showcased to our registered institutes and shared with a pool of trained candidates, inviting applications."
        },
        {
          question: "What are the advantages of hiring employees from SkillHire?",
          answer: "SkillHire provides organizations with trained, certified candidates who have hands-on experience, all at no cost. We also provide hiring tools & dedicated hiring support to simplify the hiring process."
        },
        {
          question: "Is there any hiring limit on SkillHire?",
          answer: "You're free to hire as many candidates as you need from our pool."
        },
        {
          question: "From which all locations can I recruit candidates using SkillHire?",
          answer: "Candidates from all over India are available."
        },
        {
          question: "Which all job roles can I hire using SkillHire?",
          answer: "You can hire for a wide range of skills and sectors. We have registered training and upskilling organizations from all major segments."
        }
      ]
      }
    },
    metaInfo: {
      link: [
        { rel: 'canonical', href: process.env.VUE_APP_URL }
      ],
      script: [
        {type: 'application/ld+json', json: jsonld },
        {type: 'application/ld+json', json: faqJson},
        {type: 'application/ld+json', json: breadcrumbJson},
        
      ]
    },
    beforeMount(){
      this.getStates();
      // this.getInstitutes();
      this.getInstituteCourses();
    },
    methods: {
      getInstituteCourses: function(){
        this.action.getRegisteredCourses().then(res => {
          if(res.status){
            this.courses = res.courses;
          }
        }).catch(err =>{
          console.log(err);
        })
      },
      getStates: async function () {
          await this.action.getStates().then(async (data) => {
          this.locations.push({header: 'Top Metropolitan Cities'})
          await data.states.forEach(async (element) => {
              await element.cities.forEach(city => {
                  if(city.top_city == 1){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:'Top Metropolitan Cities'})
                  }
              });
              this.locations.push({divider:true})
              this.locations.push({header: element.state_name});
              element.cities.forEach(city => {
                  if(city.top_city == 0){
                      this.locations.push({text:city.city_name,value:city.city_id, s_id:city.state_id,group:element.state_name})
                  }
              });
          });
      });
  },
    }
}
</script>
<style scope>
  v-text-field__details{display: none !important;}
  h1 {
    font-weight: 600;
  }  
  #landing-page h5{
    color: #0054ad;
    font-weight: 600;
  }
  #skill-head{
    color: #4b85e8;
  }
  #hire-head{
    color: #38761d;
  }
  .dotted-line {
    border-bottom: 1px dotted #999; /* Adjust the color and width as needed */
    margin: 10px 0; /* Optional: Add some spacing around the line */
  }
  .testimonial-section {
      text-align: right;
      padding: 20px;
      border-top: 1px dotted #ccc;
  }
  .testimonial {
    margin-bottom: 20px;
    font-style: italic;
  }
  .testimonial-name {
    font-weight: bold;
  }
  .testimonial-company {
    font-size: 0.8em;
  }
  .first-job {
    max-width: 100%;
  }
  .blue-box{
    background-color: #0054ad;
    color: #fff;
  }
  .blue-box div{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .vertical-div{
    border-right: 1px solid #fff; /* Adjust the color and width as needed */
    margin: 10px 0; 
  }
</style>